<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register">
              <template v-for="(item, index) in itemsStep">
                <li
                  :key="index"
                  :class="
                    (item.active && 'active') ||
                    (item.finish && 'navtop-canclick')
                  "
                  @click="
                    item.finish &&
                      stepwh_signup < 8 &&
                      changeStep(item.linkItems)
                  "
                >
                  <a href class="d-flex flex-column">
                    <span v-if="item.finish" class="icons">
                      <img src="@/assets/icons/icon-checkmark.svg" alt />
                    </span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>

          <!-- <div>
            <div class="form-box pb-0">
              <h3 class="title-md mb-0">{{ $t("SignupStep4.all_facilities_title") }}</h3>

              <table class="table table-facility mb-0" style="margin-top: 1.5rem">
                <thead>
                  <tr>
                    <th></th>
                    <th>{{ $t("SignupStep4.free") }}</th>
                    <th>{{ $t("SignupStep4.charge") }}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>-->

          <!-- <div class="screen-facility overflow-y-auto"> -->
          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
            <div class="form-box">
              <h3
                class="title-md mb-0"
                style="position: sticky; top: 0; z-index: 999 !important; background-color: #fff !important; height: 2.5rem;"
              >{{ $t("SignupStep4.all_facilities_title") }}</h3>

              <table class="table table-facility mt-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{{ $t("SignupStep4.free") }}</th>
                    <th>{{ $t("SignupStep4.charge") }}</th>
                  </tr>
                </thead>

                <tbody>
                  <template v-for="(item, i) in itemsSelectFacilities">
                    <tr :key="i" class="active">
                      <td>
                        <div class="checkbox-group d-block">
                          <input
                            :id="`${item._id}_active`"
                            :name="`${item._id}_active`"
                            type="checkbox"
                            v-model="item.active"
                            :checked="item.active"
                            :key="componentKey"
                            @change="
                              changeHaveFacility(
                                `${item._id}_active`,
                                item,
                                i,
                                item.active
                              )
                            "
                          />
                          <label :for="`${item._id}_active`" class="regular">
                            <span>{{ item.facilityname }}</span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="radio-group no-text">
                          <input
                            :id="`${item._id}_free`"
                            :name="`radio_${item._id}`"
                            type="radio"
                            :checked="item.free"
                            @change="cbCostPriceFacilitie(item, i, 'free')"
                            :disabled="!item.active"
                            :key="componentKey"
                          />
                          <label :for="`${item._id}_free`">
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="radio-group no-text">
                          <input
                            :id="`${item._id}_charge`"
                            :name="`radio_$${item._id}`"
                            type="radio"
                            :checked="item.free == false"
                            @change="cbCostPriceFacilitie(item, i, 'charge')"
                            :disabled="!item.active"
                            :key="componentKey"
                          />
                          <label :for="`${item._id}_charge`">
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <!-- <td v-if="item.free == null"> -->
                      <!-- <span
                        class="error-text-input"
                        v-if="item.active && item.free == null"
                        >{{ $t("Alert.pleasefilldata") }}</span
                      >-->
                      <!-- </td> -->
                      <!-- <td
                        class="text-error"
                        v-if="item.active && item.free == null"
                      >
                        *โปรดระบุข้อมูล
                      </td>-->
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="form-buttons" style="bottom: -29px">
            <div class="pa-0 d-flex flex-row align-center justify-space-between flex-100per">
              <button
                type="button"
                class="btn-back gl-bt hover_btn ml-0"
                style="text-transform: capitalize !important"
                @click="goback()"
              >
                <span>{{ `< ${$t("servicepage.back")}` }}</span>
              </button>

              <button
                type="button"
                class="btn gl-bt hover_btn mx-0"
                style="text-transform: capitalize !important"
                :disabled="!cangonextstep"
                @click="nextstep(5)"
              >
                <span>{{ $t("SignupStep4.btn_next") }}</span>
              </button>
            </div>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a
              :href="pathrightphoto"
              class="img-exemple iphone-model d-flex flex-column align-center justify-center"
              data-fancybox
            >
              <span class="btn-open-picture">
                {{
                $t("SignupStep4.clickopenpicture")
                }}
              </span>
              <img :src="pathrightphoto" alt />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";
import { fi } from "date-fns/locale";

export default {
  name: "FacilitiesScreen",
  metaInfo: {
    title: "Facilities and services",
    titleTemplate: "%s | Aigent"
  },
  data: () => ({
    stepwh_signup: 4,
    itemsArea: [],
    addTheNewFacilities: [],
    itemsRoomType: [],
    dialogSelectArea: false,
    dialogSelectAreaData: {
      area: "",
      rooms: [],
      facility: "",
      free: null
    },
    propertyID: "",
    componentKey: 0,
    value_element: "",
    itemsStep: [],
    initDataStep: {},
    itemsSelectFacilities: [],
    pathrightphoto: "",
    cangonextstep: false,
    selectCheckbox: "",
    cansavedialog: false,
    dialogselecthotel: false,
    itemsDefaultFacility: [],
    userToken: ""
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    if (self.userToken && self.propertyID) {
      self.changeLanguageData();
    } else {
      self.gotosignin();
    }
    if (
      (localStorage.getItem("Token") == "" || !localStorage.getItem("Token")) &&
      self.userToken
    ) {
      localStorage.setItem("Token", self.userToken);
    }
    localStorage.setItem("signupstep", self.stepwh_signup);
    localStorage.setItem("SignupStep", self.stepwh_signup);
  },
  mounted() {
    const self = this;
    EventBus.$on("refreshRightPhoto", self.changeLanguageData);
    self.colorHover();
  },
  watch: {},
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", e => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    changeLanguageData() {
      const self = this;
      self.initStepData();
      self.initArea();
    },
    async initStepData() {
      const self = this;
      var temp = {};
      var tempRoomtype = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          self.initFacilitiesData(res?.data?.result[0]);
          res?.data?.result?.map(el => {
            if (el._id == self.propertyID) temp = el;
          });
          self.initDataStep = temp;
          self.keepUserID = res?.data?.result[0]?.user?._id;
          if (temp?.rooms?.length) {
            tempRoomtype = [];
            temp?.rooms?.map(el => {
              const indexRoomtype = tempRoomtype?.findIndex(
                p => p._id == el.room_type._id
              );
              if (indexRoomtype < 0) {
                tempRoomtype.push({
                  _id: el._id,
                  name: el.room_name.value,
                  value: el._id
                });
              }
            });
          }
          if (res?.data?.result?.length) {
            self.itemsStep = [
              {
                name: self.$t("SignupStep.step1"),
                active: (self.stepwh_signup == 1 && true) || false,
                finish: temp?.step_signup?.step1 || false,
                linkItems: {
                  link: `signup/basic/${self.userToken}/${self.propertyID}`,
                  name: "SignupBasicInformationScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 1,
              },
              {
                name: self.$t("SignupStep.step2"),
                active: (self.stepwh_signup == 2 && true) || false,
                finish: temp?.step_signup?.step2 || false,
                linkItems: {
                  link: `signup/room/${self.userToken}/${self.propertyID}`,
                  name: "SignupRoomScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 2,
              },
              {
                name: self.$t("SignupStep.step3"),
                active: (self.stepwh_signup == 3 && true) || false,
                finish: temp?.step_signup?.step3 || false,
                linkItems: {
                  link: `signup/rateplan/${self.userToken}/${self.propertyID}`,
                  name: "SignupRatePlanScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 3,
              },
              {
                name: self.$t("SignupStep.step4"),
                active: (self.stepwh_signup == 4 && true) || false,
                finish: temp?.step_signup?.step4 || false,
                linkItems: {
                  link: `signup/facilities/${self.userToken}/${self.propertyID}`,
                  name: "SignupFacilitiesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 4,
              },
              {
                name: self.$t("SignupStep.step5"),
                active: (self.stepwh_signup == 5 && true) || false,
                finish: temp?.step_signup?.step5 || false,
                linkItems: {
                  link: `signup/pictures/${self.userToken}/${self.propertyID}`,
                  name: "SignupPicturesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 5,
              },
              {
                name: self.$t("SignupStep.step6"),
                active: (self.stepwh_signup == 6 && true) || false,
                finish: temp?.step_signup?.step6 || false,
                linkItems: {
                  link: `signup/policy/${self.userToken}/${self.propertyID}`,
                  name: "SignupPolicyScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 6,
              },
              {
                name: self.$t("SignupStep.step7"),
                active: (self.stepwh_signup == 7 && true) || false,
                finish: temp?.step_signup?.step7 || false,
                linkItems: {
                  link: `signup/payment/${self.userToken}/${self.propertyID}`,
                  name: "SignupPaymentScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 7,
              },
            ];
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        self.gotosignin();
      } finally {
        self.getStepData(self.itemsStep);
        self.itemsRoomType = tempRoomtype;
        self.initGoNextFunc();
      }
    },
    checkRoomName(data) {
      const self = this;
      const temp = self.itemsRoomType.find(p => p.value == data);
      return temp?.name || "";
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
    },
    changePhotoRight() {
      const self = this;
      var tempLG = localStorage.getItem("language") === "th" ? "th" : "en";
      self.pathrightphoto = `${process.env.VUE_APP_PIC_API}/src/step4-${tempLG}.gif`;
    },
    gotosignin() {
      var item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async initArea() {
      const self = this;
      var temp = [];
      var item = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/setting/area?lang=${localStorage.getItem("language") ||
              self.$store.state.language}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          item = res.data.result;
          item.map(el => {
            temp.push({
              _id: el._id,
              name: el.value,
              value: el._id
            });
          });
          self.itemsArea = temp;
        }
      } catch (error) {
        console.log(error?.response?.data?.message);
      }
    },
    async initFacilitiesData(temp) {
      const self = this;
      var tempFacility = [];
      var tempHaveFacility = [];
      self.addTheNewFacilities = [];

      if (temp?.facilities?.length) {
        tempFacility = temp.facilities;
      }
      var tempRooms = [];
      tempFacility?.map(element => {
        if (element?._id && element?.facility?.value)
          tempHaveFacility.push({
            _id: element?._id,
            active: element?.active || null,
            facility: element?.facility?._id || "-",
            facilityname: element?.facility?.value || "-",
            free: element?.active ? element?.free : null
          });
      });
      self.itemsSelectFacilities = tempHaveFacility;
    },
    checkAreaFunc(data) {
      const self = this;
      const temp = self.itemsArea.find(p => p._id == data);
      // console.log(temp)
      return temp?.name || "";
    },
    changeHaveFacility(name, item, indexFacility, value) {
      const self = this;
      self.selectCheckbox = name;
      self.componentKey = 0;
      if (item.active) {
        if (item.free == null || item.free == "undefined") item.free = true;
      } else {
        item.free = null;
      }
      self.checkcangonext(name, item, indexFacility, value);
    },
    cbCostPriceFacilitie(item, indexFacility, value) {
      const self = this;
      if (value === "free")
        self.itemsSelectFacilities[indexFacility].free = true;
      else self.itemsSelectFacilities[indexFacility].free = false;
      self.initGoNextFunc();
      self.forceRerender();
    },
    checkcangonext(name, item, indexFacility, value) {
      const self = this;
      self.cangonextstep = false;

      if (value == false) {
        if (self.dialogSelectArea) {
          self.itemsSelectFacilities[indexFacility].active = true;
        } else {
          self.itemsSelectFacilities[indexFacility].active = false;
          self.itemsSelectFacilities[indexFacility].free = null;
        }
        self.initGoNextFunc();
      } else {
        self.initGoNextFunc();
      }
    },
    initGoNextFunc() {
      const self = this;
      var numHave = 0;
      var numAreas = 0;
      self?.itemsSelectFacilities?.map(element => {
        if (element.active == true) {
          numHave = numHave + 1;
          if (element.free == true || element.free == false) {
            // if (element?.areas[0]?.area || element?.areas[0]?.rooms?.length) {
            numAreas = numAreas + 1;
            // }
          }
        }
      });
      // if (numHave == numAreas && numHave && numAreas) self.cangonextstep = true;
      if (numHave) self.cangonextstep = true;
    },
    openDialogSelectArea(name, item, indexFacility) {
      const self = this;
      var temp = {
        _id: item?._id || "",
        indexFacility: indexFacility,
        areas: item?.areas || [
          {
            area: "",
            rooms: []
          }
        ],
        area: item?.areas[0]?.area || "",
        rooms: item?.areas[0]?.rooms || [],
        allrooms: item?.areas[0]?.rooms?.length == self.itemsRoomType?.length,
        facility: item?.facility || "",
        facilityname: item?.facilityname || "",
        free: item?.free,
        have: true
      };
      self.dialogSelectArea = true;
      self.dialogSelectAreaData = temp;
      self.itemsSelectFacilities[indexFacility] = temp;
      self.itemsSelectFacilities[indexFacility].active = true;
      self.forceRerender();
      self.checkcansavedialogFunc();
    },
    closeDialogSelectArea() {
      const self = this;
      self.dialogSelectAreaData = {
        rooms: [],
        area: "",
        allrooms: false
      };
      self.componentKey = 0;
      self.dialogSelectArea = false;
      self.initGoNextFunc();
    },
    saveSelectArea() {
      const self = this;
      const obj = {
        _id: self?.dialogSelectAreaData?._id || "",
        facility: self?.dialogSelectAreaData?.facility || "",
        facilityname: self.dialogSelectAreaData?.facilityname || "",
        free: self?.dialogSelectAreaData?.free,
        areas: [
          {
            area: self.dialogSelectAreaData.area,
            rooms: self.dialogSelectAreaData.rooms
          }
        ],
        have: self?.dialogSelectAreaData?.area ? true : false
      };
      self.itemsSelectFacilities[self.dialogSelectAreaData.indexFacility] = obj;
      const tempArea = self.itemsArea.find(
        p => p.value == self.dialogSelectAreaData.area
      );
      if (
        tempArea?.name == "Hotel" ||
        tempArea?.name == "โรงแรม" ||
        tempArea?.name == "酒店" ||
        tempArea?.name == "Public Area" ||
        tempArea?.name == "พื้นที่สาธารณะ" ||
        tempArea?.name == "公共区域"
      ) {
        self.closeDialogSelectArea();
        // self.updateFacilities(self.itemsSelectFacilities)
      } else if (
        !self?.dialogSelectAreaData?.area ||
        self?.dialogSelectAreaData?.rooms?.length == 0
      ) {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
      } else {
        self.closeDialogSelectArea();
        // self.updateFacilities(self.itemsSelectFacilities)
      }
    },
    cancelSelectArea() {
      const self = this;
      self.addTheNewFacilities = [];
      // self.initFacilitiesData()
      self.closeDialogSelectArea();
    },
    checkcansavedialogFunc() {
      const self = this;
      const tempArea = self.itemsArea.find(
        p => p.value == self.dialogSelectAreaData.area
      );
      if (
        tempArea?.name == "Hotel" ||
        tempArea?.name == "โรงแรม" ||
        tempArea?.name == "酒店" ||
        tempArea?.name == "Public Area" ||
        tempArea?.name == "พื้นที่สาธารณะ" ||
        tempArea?.name == "公共区域"
      ) {
        self.cansavedialog = true;
        self.dialogselecthotel = true;
        self.dialogSelectAreaData.rooms = [];
      } else {
        self.dialogselecthotel = false;
        if (
          self?.dialogSelectAreaData?.area == "" ||
          (self?.dialogSelectAreaData?.rooms &&
            self?.dialogSelectAreaData?.rooms?.length == 0)
        ) {
          self.cansavedialog = false;
        } else self.cansavedialog = true;
      }
      if (
        self?.dialogSelectAreaData?.rooms?.length == self?.itemsRoomType?.length
      )
        self.dialogSelectAreaData.allrooms = true;
      else self.dialogSelectAreaData.allrooms = false;
      self.forceRerender();
    },
    changeAllRooms() {
      const self = this;
      var temp = [];
      if (self.dialogSelectAreaData.allrooms === true) {
        self.itemsRoomType.map(el => {
          temp.push(el.value);
        });
        self.dialogSelectAreaData.rooms = temp;
      } else {
        self.dialogSelectAreaData.rooms = [];
      }
      self.checkcansavedialogFunc();
      return self.dialogSelectAreaData.rooms;
    },
    async nextstep(num) {
      const self = this;
      const obj = {
        facilities: self.itemsSelectFacilities
      };
      // console.log('facilities: ', obj)
      const linkItems = {
        link: `signup/pictures/${self.userToken}/${self.propertyID}`,
        name: "SignupPicturesScreen",
        params: { userToken: self.userToken, propertyId: self.propertyID }
      };
      var numofchoices = 0;
      var numofchoicesdata = 0;

      EventBus.$emit("loadingtillend");

      self?.itemsSelectFacilities?.map(el => {
        if (el.active) {
          numofchoices = numofchoices + 1;
          if (el.free !== null) numofchoicesdata = numofchoicesdata + 1;
        }
      });

      if (numofchoices !== numofchoicesdata) {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
      } else {
        try {
          const res = await self.axios.put(
            process.env.VUE_APP_API +
              `/property/step4?property_id=${self.propertyID}&next=${1}`,
            obj,
            {
              headers: {
                Authorization:
                  self?.userToken ||
                  localStorage?.getItem("Token") ||
                  self?.$store?.state?.resSignUpData?.access_token,
                "X-Language-Code":
                  localStorage?.getItem("language") ||
                  self?.$store?.state?.language
              }
            }
          );
          if (res.status == 200) {
            EventBus.$emit("endloading");
            EventBus.$emit("changePathname", linkItems);
          } else {
            EventBus.$emit("endloading");
            swal(
              self.$t("Alert.warn_title"),
              res?.message || self.$t("Alert.tryagain"),
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000
              }
            );
          }
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
          swal(
            self.$t("Alert.warn_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        }
      }
    },
    async changeStep(linkItems) {
      const self = this;
      const obj = {
        facilities: self.itemsSelectFacilities
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step4?property_id=${self.propertyID}&next=${0}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          EventBus.$emit("changePathname", linkItems);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
      }
    },
    goback() {
      const self = this;
      var item = {
        link: `signup/rateplan/${self.propertyID}`,
        name: "SignupRatePlanScreen",
        params: { propertyId: self.propertyID }
      };
      EventBus.$emit("changePathname", item);
    }
  }
};
</script>

<style>
thead tr th {
  position: sticky;
  top: 2.5rem;
  z-index: 999 !important;
  background-color: #fff !important;
}

.screen-facility {
  height: calc(100% - 120px) !important;
  max-height: 55vh !important;
  overflow-y: scroll !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 7.5rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
@media (min-width: 321px) and (max-width: 767px) {
  .siq_bR {
    width: 4.5rem !important;
    height: 3rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 4.5rem !important;
    height: 3rem !important;
    object-fit: contain !important;
  }
}
@media (max-width: 320px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
</style>
